/*
 *
 * ViewCart constants
 *
 */

export const LOAD_CART_REQUEST = "app/ViewCart/LOAD_CART_REQUEST";
export const LOAD_CART_SUCCESS = "app/ViewCart/LOAD_CART_SUCCESS";
export const LOAD_CART_FAILURE = "app/ViewCart/LOAD_CART_FAILURE";
export const LOAD_SHIPPING_PRICE = "app/ViewCart/LOAD_SHIPPING_PRICE";

export const DELETE_FROM_CART_REQUEST = "app/ViewCart/DELETE_FROM_CART_REQUEST";
export const DELETE_FROM_CART_SUCCESS = "app/ViewCart/DELETE_FROM_CART_SUCCESS";
export const DELETE_FROM_CART_FAILURE = "app/ViewCart/DELETE_FROM_CART_FAILURE";

export const ADD_TO_CART_REQUEST = "app/ViewCart/ADD_TO_CART_REQUEST";
export const ADD_TO_CART_SUCCESS = "app/ViewCart/ADD_TO_CART_SUCCESS";
export const ADD_TO_CART_FAILURE = "app/ViewCart/ADD_TO_CART_FAILURE";

export const CLEAR_CART_DATA = "app/ViewCart/CLEAR_CART_DATA";

export const SET_CART_DATA = "app/ViewCart/SET_CART_DATA";
export const SET_COUPON = "app/ViewCart/SET_COUPON";
export const CLEAR_ERROR = "app/ViewCart/CLEAR_ERROR";
export const SET_BUTTON_DISABLED = "app/ViewCart/SET_BUTTON_DISABLED";

export const APPLY_FOR_COUPON_REQUEST = "app/ViewCart/APPLY_FOR_COUPON_REQUEST";
export const APPLY_FOR_COUPON_SUCCESS = "app/ViewCart/APPLY_FOR_COUPON_SUCCESS";
export const APPLY_FOR_COUPON_FAILURE = "app/ViewCart/APPLY_FOR_COUPON_FAILURE";

export const ADD_TO_FAVORITE_REQUEST = "app/ViewCart/ADD_TO_FAVORITE_REQUEST";
export const ADD_TO_FAVORITE_SUCCESS = "app/ViewCart/ADD_TO_FAVORITE_SUCCESS";
export const ADD_TO_FAVORITE_FAILURE = "app/ViewCart/ADD_TO_FAVORITE_FAILURE";

export const BUY_NOW_REQUEST = "app/ViewCart/BUY_NOW_REQUEST";
export const BUY_NOW_SUCCESS = "app/ViewCart/BUY_NOW_SUCCESS";
export const BUY_NOW_FAILURE = "app/ViewCart/BUY_NOW_FAILURE";

export const SET_SHOW_LIST = "app/ViewCart/SET_SHOW_LIST";
export const SET_SHIPPING_PRICE = "app/ViewCart/SET_SHIPPING_PRICE";
