import React from "react";
import PropTypes from "prop-types";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  makeSelectToken,
  makeSelectUserIsAdmin,
  makeSelectLocation,
} from "../../../containers/App/selectors";

const AdminRoute = ({ token, isAdmin, children }) => {
  if (token && isAdmin) return children;
  // if (token) return children;

  if (token && isAdmin === false) {
    return <Navigate to="/notfound" />;
  }
  return <Navigate to="/account" />;
};

AdminRoute.propTypes = {
  token: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

AdminRoute.defaultProps = {
  isAdmin: false,
};

const mapStateToProps = createStructuredSelector({
  token: makeSelectToken(),
  location: makeSelectLocation(),
  isAdmin: makeSelectUserIsAdmin(),
});

export default connect(mapStateToProps)(AdminRoute);
// export default AdminRoute;
