import {
  call,
  takeEvery,
  takeLatest,
  select,
  put,
  takeLeading,
  delay,
} from "redux-saga/effects";
import { push } from "redux-first-history";
import Api from "../../utils/Api";
import * as types from "./constants";
import * as actions from "./actions";
import { clearCartData } from "../ViewCart/actions";
import { makeSelectToken } from "./selectors";
import { enqueueSnackbar } from "notistack";

function* loadContent(action) {
  yield call(
    Api.get(
      `contents/key/${action.payload}`,
      actions.loadContentSuccess,
      actions.loadContentFailure
    )
  );
}

function* logOut(action) {
  const token = yield select(makeSelectToken());
  yield call(
    Api.get(`user/logout`, actions.logoutSuccess, actions.logoutFailure, token)
  );
  if (action.payload) {
    yield put(push("/"));
  }
}
function* loadSlide(action) {
  yield call(
    Api.get(
      `slider/key/${action.payload}`,
      actions.loadSlideSuccess,
      actions.loadSlideFailure
    )
  );
}

function* sessionExpired() {
  // token expired case, logout user and show alert that relogin is required
  yield put(actions.logoutSuccess());

  const snackbarData = {
    message: "User Session expired. please login again",
    options: {
      variant: "warning",
    },
  };
  enqueueSnackbar(snackbarData.message, { variant: "warning" });

  // yield put(actions.enqueueSnackbar(snackbarData));
  yield delay(2000);
  // alert('User Session expired. please login again');
}

function* networkError() {
  // token expired case, logout user and show alert that relogin is required

  const snackbarData = {
    message: "Something went wrong. Please check your network!",
    options: {
      variant: "warning",
    },
  };
  enqueueSnackbar(snackbarData.message, { variant: "warning" });

  // yield put(actions.enqueueSnackbar(snackbarData));
  yield delay(2000);
  // alert('User Session expired. please login again');
}

function* loadMenu(action) {
  yield call(
    Api.get(
      `menu/detailforuser/${action.payload}`,
      actions.loadMenuSuccess,
      actions.loadMenuFailure
    )
  );
}

function* loadCategory() {
  const token = yield select(makeSelectToken());
  yield call(
    Api.get(
      "category/category?count=product",
      actions.loadCategorySuccess,
      actions.loadCategoryFailure,
      token
    )
  );
}

function* logoutSuccessFunc(action) {
  yield put(clearCartData());
}

export default function* defaultSaga() {
  yield takeEvery(types.LOAD_MENU_REQUEST, loadMenu);
  yield takeLatest(types.LOAD_CATEGORY_REQUEST, loadCategory);
  yield takeLatest(types.LOGOUT_REQUEST, logOut);
  yield takeEvery(types.LOAD_SLIDE_REQUEST, loadSlide);
  yield takeEvery(types.LOAD_CONTENT_REQUEST, loadContent);

  yield takeLatest(types.LOGOUT_SUCCESS, logoutSuccessFunc);
  yield takeLeading(types.SESSION_EXPIRED, sessionExpired);
  yield takeLeading(types.NETWORK_ERROR, networkError);
}
