import { call, put } from "redux-saga/effects";
import { API_BASE } from "../containers/App/constants";
// const API_BASE = process.env.REACT_APP_API_BASE;

import { sessionExpired, networkError } from "../containers/App/actions";
import objectToFormData from "./objectToFormData";
import request from "./request";

class Api {
  /**
   * Generic api data loader
   */
  static dataLoader(apiUri, onSuccess, onError, data, token, metaData) {
    console.log(data, token, process.env.REACT_APP_API_BASE, "data966");
    return function* commonApiSetup() {
      const baseUrl = process.env.REACT_APP_API_BASE;
      // const baseUrl = API_BASE;

      const requestURL = `${baseUrl}${apiUri}`;
      try {
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        };
        console.log("1");

        if (data !== undefined) {
          console.log("2");
          options.method = metaData === "put" ? "PUT" : "POST";
          options.body = JSON.stringify(data);
        }
        console.log(options, "options");
        const response = yield call(request, requestURL, options);
        console.log(response, "roo");
        yield put(onSuccess(response));
        return response;
      } catch (err) {
        let error = null;
        try {
          console.log(err, "erro8888");
          const errorPromise = err.response.json();
          error = yield call(() => errorPromise);
          console.log(error.errors, "erro222");

          if (error.errors && error.errors.name === "JsonWebTokenError") {
            yield put(sessionExpired(error));
          } else {
            yield put(onError(error));
          }
        } catch (e) {
          console.log(e, "eee");
          yield put(networkError(e));
          yield put(onError(e));
        }
      }
    };
  }
  static dataLoader2(apiUri, onSuccess, onError, data, token, metaData) {
    console.log(data, token, "data");
    return function* commonApiSetup() {
      const baseUrl = process.env.REACT_APP_API_BASE;
      // const baseUrl = API_BASE;

      const requestURL = `${baseUrl}${apiUri}`;
      try {
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        };
        console.log("1");

        if (data !== undefined) {
          console.log("2");
          options.method = metaData === "put" ? "PUT" : "POST";
          options.body = JSON.stringify(data);
        }
        console.log(options, "options");
        const response = yield call(request, requestURL, options);
        console.log(response, "roo");
        // yield put(onSuccess(response));
        return response;
      } catch (err) {
        let error = null;
        try {
          console.log(err, "erro8888");
          const errorPromise = err.response.json();
          error = yield call(() => errorPromise);
          console.log(error.errors, "erro222");

          if (error.errors && error.errors.name === "JsonWebTokenError") {
            yield put(sessionExpired(error));
          } else {
            yield put(onError(error));
          }
        } catch (e) {
          console.log(e, "eee");
          yield put(networkError(e));
          yield put(onError(e));
        }
      }
    };
  }

  static dataLoader1(apiUri, onSuccess, onError, data, token, metaData) {
    return function* commonApiSetup() {
      const baseUrl = "https://www.waftengine.org/api/";
      const requestURL = `${baseUrl}${apiUri}`;
      try {
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        };
        if (data !== undefined) {
          options.method = metaData === "put" ? "PUT" : "POST";
          options.body = JSON.stringify(data);
        }
        const response = yield call(request, requestURL, options);
        yield put(onSuccess(response));
      } catch (err) {
        let error = null;
        try {
          error = yield call(() => err.response.json());
          if (error.errors.name === "JsonWebTokenError") {
            yield put(sessionExpired(error));
          } else {
            yield put(onError(error));
          }
        } catch (e) {
          yield put(networkError(e));
          yield put(onError());
        }
      }
    };
  }

  static multipartPost(
    apiUri,
    onSuccess,
    onError,
    data,
    document = {},
    token,
    metaData
  ) {
    return function* multiPartApiSetup() {
      const requestURL = `${process.env.REACT_APP_API_BASE}${apiUri}`;
      let multipartData = new FormData();
      console.log(data,'data66')
      multipartData = objectToFormData(data, multipartData);
      console.log(document, "document");
      Object.keys(document).map((each) => {
        console.log(each,'eachdocs11')
        if (Array.isArray(document[each])) {
          document[each].map((fileObj) =>
            multipartData.append([each], fileObj)
          );
        console.log(each,'eachdocs22')

        } else {
          multipartData.append([each], document[each]);
        console.log(each,'eachdocs33')

        }
        return null;
      });
      try {
        const options = {
          method: metaData === "put" ? "PUT" : "POST",
          body: multipartData,
          headers: {
            Authorization: token,
          },
        };
        console.log(multipartData, "multipartData888");
        const response = yield call(request, requestURL, options);
        yield put(onSuccess(response));
      } catch (err) {
        let error = null;
        try {
          error = yield call(() => err.response.json());
          if (error.errors.name === "JsonWebTokenError") {
            yield put(sessionExpired(error));
          } else {
            yield put(onError(error));
          }
        } catch (e) {
          yield put(onError(error));
        }
      }
    };
  }

  static multipartPost2(
    apiUri,
    onSuccess,
    onError,
    data,
    document = {},
    token,
    metaData
  ) {
    return function* multiPartApiSetup() {
      const requestURL = `${process.env.REACT_APP_API_BASE}${apiUri}`;
      const multipartData = new FormData();
  
      // Step 1: Separate non-file data and file data
      const nonFileData = {};
      const fileData = {};
  
      Object.keys(data).forEach((key) => {
        if (data[key] instanceof File || (Array.isArray(data[key]) && data[key].every(item => item instanceof File))) {
          fileData[key] = data[key]; // Add files to fileData
        } else {
          nonFileData[key] = data[key]; // Add non-file data to nonFileData
        }
      });
  
      // Step 2: Append non-file data as a JSON string
      multipartData.append('data', JSON.stringify(nonFileData));
  
      // Step 3: Append file data
      Object.keys(fileData).forEach((key) => {
        if (Array.isArray(fileData[key])) {
          fileData[key].forEach((file) => {
            multipartData.append(key, file); // Append each file in the array
          });
        } else {
          multipartData.append(key, fileData[key]); // Append single file
        }
      });
  
      // Step 4: Append additional documents (if any)
      Object.keys(document).forEach((key) => {
        if (Array.isArray(document[key])) {
          document[key].forEach((file) => {
            multipartData.append(key, file); // Append each file in the array
          });
        } else {
          multipartData.append(key, document[key]); // Append single file
        }
      });
  
      try {
        const options = {
          method: metaData === "put" ? "PUT" : "POST",
          body: multipartData,
          headers: {
            Authorization: token,
          },
        };
  
        const response = yield call(request, requestURL, options);
        yield put(onSuccess(response));
      } catch (err) {
        let error = null;
        try {
          error = yield call(() => err.response.json());
          if (error.errors.name === "JsonWebTokenError") {
            yield put(sessionExpired(error));
          } else {
            yield put(onError(error));
          }
        } catch (e) {
          yield put(onError(error));
        }
      }
    };
  }
  /*
   * Shorthand GET function
   */
  static get(apiUri, onSuccess, onError, token) {
    return this.dataLoader(apiUri, onSuccess, onError, undefined, token);
  }

  static get1(apiUri, onSuccess, onError, token) {
    return this.dataLoader1(apiUri, onSuccess, onError, undefined, token);
  }

  static get2(apiUri, onSuccess, onError, token) {
    return this.dataLoader2(apiUri, onSuccess, onError, undefined, token);
  }

  /*
   * Shorthand POST function
   */
  static post(apiUri, onSuccess, onError, data, token) {
    return this.dataLoader(apiUri, onSuccess, onError, data, token);
  }

  /*
   * Shorthand PUT function
   */
  static put(apiUri, onSuccess, onError, data, token, metaData = "put") {
    return this.dataLoader(apiUri, onSuccess, onError, data, token, metaData);
  }

  /*
   * Shorthand PATCH function
   */
  static patch(apiUri, onSuccess, onError, data, token) {
    return function* patchApiSetup() {
      const requestURL = `${process.env.REACT_APP_API_BASE}${apiUri}`;
      try {
        const options = {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(data),
        };
        const response = yield call(request, requestURL, options);
        yield put(onSuccess(response));
      } catch (err) {
        let error = null;
        try {
          error = yield call(() => err.response.json());
          if (error.errors.name === "JsonWebTokenError") {
            yield put(sessionExpired(error));
          } else {
            yield put(onError(error));
          }
        } catch (e) {
          yield put(networkError(e));
          yield put(onError());
        }
      }
    };
  }

  /*
   * Shorthand DELETE function
   */
  static delete(apiUri, onSuccess, onError, token) {
    return function* deleteApiSetup() {
      const requestURL = `${process.env.REACT_APP_API_BASE}${apiUri}`;
      try {
        // Call our request helper (see 'utils/request')
        const options = {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        };
        const response = yield call(request, requestURL, options);
        yield put(onSuccess(response));
        // if (response.success) {
        //   yield put(onSuccess(response));
        // } else {
        //   yield put(onError(response));
        // }
      } catch (err) {
        let error = null;
        try {
          error = yield call(() => err.response.json());
          if (error.errors.name === "JsonWebTokenError") {
            yield put(sessionExpired(error));
          } else {
            yield put(onError(error));
          }
        } catch (e) {
          yield put(networkError(e));
          yield put(onError());
        }
      }
    };
  }
}

export default Api;
